var $ = require('jQuery');
var fitvids = require('fitvids');
var imagesLoaded = require('imagesloaded');
var Masonry = require('masonry-layout');

window.jQuery = $;

imagesLoaded.makeJQueryPlugin( $ );

fitvids();
// var msnry = new Masonry( '.nc-grid-inner', {
// 	 itemSelector: '.nc-grid-item',
// 	 columnWidth: '.nc-grid-sizer',
// 	 percentPosition: true,
// 	 gutter: 0,
// 	 margin: 0,
// 	 transitionDuration: 0
// });

$('.nc-grid-inner' ).each(function(){
	var $this = $(this);
	$this.imagesLoaded( function(){	
		var msnry = new Masonry( $this[0], {
			itemSelector: '.nc-grid-item',
			columnWidth: '.nc-grid-sizer',
			percentPosition: true,
			gutter: 0,
			margin: 0,
			transitionDuration: 0
		});
		msnry.layout();
	});
});
// imagesloaded( '.nc-grid-inner', function(){
// 	msnry.layout();
// })

$('.nc-menu-toggle').click(function(){
	$('html').toggleClass('state--menu-visible');
});

/* ZOOM FUNCTIONALITY */
if( $('body').hasClass('page-template-image-set') ){
	var $zoom = $('<div class="nc-zoom"></div>');
	var $images = $('.nc-image-set img');
	var $current_image = false;
	var animWaitTimeout = false;

	$zoom.click(function(){
		$zoom.removeClass('visible');
		clearTimeout(animWaitTimeout);
		animWaitTimeout = setTimeout(function(){
			$zoom.detach();
		}, 500 );		
	});

	$images.click(function(){
		$current_image = $images.index( $(this ) );
		var $image_clone = $(this).clone();		
		$image_clone.attr('sizes', '100vw');
		$image_clone.click(function( e ){
			e.stopPropagation();
			var next = $images.get( $current_image + 1 );
			var $next = $(next);
			if( $next.length > 0 ){
				$next.click();
			} else {
				$zoom.click();
			}
		});
		$zoom
			.empty()
			.append( $image_clone );		
		$('body').append( $zoom );
		clearTimeout(animWaitTimeout);		
		nc_sizeZoom();
		animWaitTimeout = setTimeout(function(){
			$zoom.addClass('visible');
		}, 10 );
	});

	var nc_sizeZoom = function(){
		var $img = $zoom.find('img');
		var maxW = $zoom.width();		
		var maxH = $zoom.height();
		var w = parseInt( $img.attr('width') );
		var h = parseInt( $img.attr('height') );
		var ratio = w/h;
		var newW = w;
		var newH = h;

		if( $(window).width() > 1024 ){
			maxW = maxW * 0.66;
		}
		
		
		if( w > h ){
			newW = maxW;
			newH = maxW/ratio;
			if( newH > maxH ){
				newH = maxH;
				newW = maxH * ratio;
			}		
		} else {
			newH = maxH;
			newW = maxH * ratio;
			if( newW > maxW ){
				newW = maxW;
				newH = maxW / ratio;
			}
		}
		
		$img.css({
			width: newW,
			height: newH
		});
		
		$(window).on('resize', function(){
			nc_sizeZoom();
		});
	}
}
